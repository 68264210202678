@media (max-width:500px) {
  .personData {

    width: min(110px, 100%) !important;

  }
  .persons{
    justify-content: space-between;
  }


}

@media (max-width: 410px){
  .qrImage {
    width: 60px !important;
  }
  .persons{
    justify-content: space-between;
  }

  .rounded-text {
    width: 100.625px !important;
    height: 100.625px !important;

  }

  .personData{
    width: min(60px, 100%) !important;
  }

  .rounded-text img{
    width: 70px !important;
    padding: 2px;

  }
  #home{
    padding: 10px;
  }
}

* {
  font-family: 'Tajawal', sans-serif;
}