@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body,
#home {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.bg-image {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(90deg, #00000089, #00000090),
    url("https://res.cloudinary.com/duovxefh6/image/upload/v1696686206/slide4-3_o8bnek.jpg");
  background-position: center;
  background-size: cover;
  filter: blur(4px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  max-width: 800px;
  width: min(800px, 100%);
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.488);
  backdrop-filter: blur(19px);
  border-radius: 7px;
}

.container .text {
  text-align: center;
  font-size: 41px;
  /* font-weight: 600; */
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
}

.container form {
  padding: 30px 0 0 0;
}

.container form .form-row {
  display: flex;
  margin: 32px 0;
}

form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
  display: flex;

  background-color: transparent !important;
}

form .form-row .textarea {
  height: 70px;
}

.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid white;
  background-color: transparent !important;
  color: white;
}

.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: white;
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}

.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  text-align: start;
  color: white;
}

.textarea label {
  width: 100%;
  bottom: 40px;
  color: white;
}

.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}

.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
  border-radius: 7px;
}

.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #f6ae22,
    #005c73,
    #f6ae22,
    #005c73
  );
  border-radius: 7px;
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}

.submit-btn .input-data input {
  background: none;
  border: none;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  background: transparent !important;
}

@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }

  .container form {
    padding: 10px 0 0 0;
  }

  .container form .form-row {
    display: block;
  }

  form .form-row .input-data {
    margin: 35px 0 !important;
    display: flex;
  }

  .submit-btn .input-data {
    width: 40% !important;
  }

  .container {
    padding: 25px 20px 10px 20px;
  }
}

.input-data .underline:before {
  left: 0 !important;
}

.persons {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.persons > img {
  width: 50px;
  margin: auto 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Toastify {
  position: fixed !important;
}

.Toastify__toast-container--top-right {
  top: 10rem !important;
}

@media (max-width: 772px) {
  .Toastify__toast-container--top-right {
    top: 22rem !important;
  }
}
