.personData hr{
    width: 90%;
    border-radius: 7px;
    margin: auto;
    height: 3px;
    background-color: white;
}

.personData {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: .1px solid white; */
    width: min(140px, 100%);
    align-items: center;
    border-radius: 14px;
}