.rounded-text img {
  position: absolute;
  width: 120px;
  /* width: 100%; */
  cursor: pointer;
  top: 50%;
  left: 50%;
  /* height: 60px; */
  translate: -50% -50%;
  border-radius: 120px;
}

.rounded-text {
  width: 140.625px;
  height: 140.625px;
  text-transform: uppercase;
  /* transform: rotate(-103deg); */
  color: #fff;
  font-weight: bold;
  z-index: -1;
  font-family: Raleway;
  position: relative;
}

.rounded-text img {
  position: absolute;
  padding: 20px;
}

.white {
  fill: #fff;
}

.yellow {
  fill: #ffda2a;
}

.green {
  fill: #00d3b9;
}

@keyframes rotating {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.rotating svg {
  animation: rotating 12s linear infinite;
  color: white;
  fill: #fff;
}

@keyframes coloring {
  0% {
    fill: #fff;
  }

  35% {
    fill: #ffda2a;
  }

  75% {
    fill: #00d3b9;
  }

  100% {
    fill: #fff;
  }
}

.coloring {
  animation: coloring 12s linear infinite;
  fill: #fff;
}

h3.logoName {
  text-align: center;
  padding: 10px;
  /* background: white; */
  border-radius: 10px;
  width: fit-content;
  color: white;
  margin: auto;
}
